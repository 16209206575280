import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/pageheader"
import Padding from "../components/padding"
import styled from "styled-components"
import Layout from "../components/layout"
import Stappen from "../components/stappen"
import Stappen2 from "../components/stappen2"
import SEO from "../components/seo"
import { device } from "../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
}
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
    @media ${device.laptopL}{
        padding: 0 20px;
        
    }

    @media ${device.mobileL}{
        padding: 0 20px;
    }

`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li{
        font-weight: 600;
        width: 50%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Subheader = styled.h2`
    padding-top: 20px;
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);

    @media ${device.laptopL}{
        padding: 20px 20px 10px 20px;
    }
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`

    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding-bottom: 60px;
        
    }

    @media ${device.mobileL}{
        padding-bottom: 60px;
    }

    
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`
const FlexdividerR = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column-reverse;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: ... | ... | ... |;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Imagecontainer = styled.div`
    height: 150px;
    .gatsby-image-wrapper{
        max-height: -webkit-fill-available;
        height: 100%;
    }
    img{
        overflow: visible !important;
        object-fit: contain !important;
        
        
    }
    picture{
        overflow: visible !important;
        height: 150px;
        img{
            object-fit: contain !important;
            overflow: visible !important;
            display:relative;
            height: 150px;
        }
    }
    }


`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        niwoImage: file(relativePath: { eq: "certificaten/color/Niwo.jpeg" }) {
          childImageSharp {
            fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid
              }
          }
        },
        isoImage: file(relativePath: { eq: "certificaten/color/iso27001.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid
            }
          }
        },
        weeelabexImage: file(relativePath: { eq: "certificaten/color/weeelabex.png" }) {
          childImageSharp {
            fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid
            }
          }
        },
        blanccoImage: file(relativePath: { eq: "certificaten/color/blancco.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid
              }
          }
        },
        dinImage: file(relativePath: { eq: "certificaten/color/din66399.png" }) {
            childImageSharp {
                fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        erkendImage: file(relativePath: { eq: "certificaten/color/erkend.png" }) {
            childImageSharp {
                fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
      }
    `)
    const image = [<Img className={className} fluid={data.niwoImage.childImageSharp.fluid} />, <Img className={className} fluid={data.isoImage.childImageSharp.fluid} />, <Img className={className} fluid={data.weeelabexImage.childImageSharp.fluid} />, <Img className={className} fluid={data.blanccoImage.childImageSharp.fluid} />, <Img className={className} fluid={data.dinImage.childImageSharp.fluid} />, <Img className={className} fluid={data.erkendImage.childImageSharp.fluid} />]
    return image[something];
    }


const Certificering = () => (
    
  <Layout>
    <SEO title="Recycling" />
    <PageHeader title="Certificering">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink aria-current="page" href="/certificering/" onClick={handleClick}>
            Certificering
          </ Crumblink>
        </ Styledcrumbs>
    </ PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <FlexdividerR>
                        <div>
                        <Subheader>
                            Niwo
                        </Subheader>
                        <Paragraph>
                            Voor bedrijven die zich in Nederland bezighouden met het vervoeren, inzamelen, handelen en/of bemiddelen van bedrijfsafvalstoffen of gevaarlijke afvalstoffen is vermelding op de landelijke VIHB-lijst verplicht. De NIWO verleent de aanvragen voor vermelding op de VIHB-lijst. Green Digital is NIWO/VIHB gecertificeerd en de lijst van geregistreerde ondernemers is openbaar en terug te vinden op deze lijst.
                        </ Paragraph>
                        </ div>
                        <Imagecontainer>
                            <Headerimage something="0" className="niwo" />
                        </ Imagecontainer>
                    </ FlexdividerR>
                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <Imagecontainer>
                            <Headerimage something="1" />
                        </ Imagecontainer>
                        <div>
                            <Subheader>
                                ISO20071
                            </Subheader>
                            <Paragraph>
                                ISO 27001 is een wereldwijd erkende norm op het gebied van informatiebeveiliging. De norm beschrijft hoe u procesmatig met het beveiligen van informatie kunt omgaan, met als doel om de vertrouwelijkheid, beschikbaarheid en integriteit van informatie binnen uw organisatie zeker te stellen. Denk hierbij aan het beschermen van persoons- en/of bedrijfsgegevens, bescherming tegen hackers en inbraak. Green Digital is ISO27001 gecertificeerd en is als organisatie geauditeerd op de hoogste normen voor informatieveiligheid. 
                            </ Paragraph>
                        </ div>

                    </ Flexdivider>
                </Vertpad>
                <Vertpad>
                    <FlexdividerR>
                        <div>
                            <Subheader>
                                Weeelabex
                            </Subheader>
                            <Paragraph>
                                De "WEEE-directive", of AEEA richtlijn is een Europese geaccrediteerde richtlijn voor afval in de vorm van elektrische en elektronische apparaten, die sinds 2003 Europese wetgeving is, in 2012 is herzien en sinds 2014 verwerkt in Nederlandse wetgeving. WEEE staat voor "Waste of Electric and Electronic Equipment". De Nederlandse afkorting is "Afgedankte elektrische en elektronische apparatuur". De richtlijn bevat specifieke eisen voor be- en verwerkers van afgedankte elektrische en elektronische apparatuur op het gebied van kwaliteit, milieu en (arbo)veiligheid. Met de WEEELABEX certificering voldoet een organisatie aantoonbaar aan de wettelijke eisen en het is het enige certificaat waarmee een organisatie aan de wettelijke verplichting kan voldoen. Vanaf 1 juli 2015 is een wettelijke regeling van kracht die verplicht dat bewerkers en verwerkers van afgedankte elektrische en elektronische apparatuur in Nederland, gecertificeerd dienen te zijn op basis van WEEELABEX (of Cenelec). Green Digital is WEEELABEX gecertificeerd voor recycling en re-use van elektronische apparatuur.  
                            </ Paragraph>
                        </ div>
                        <Imagecontainer>
                            <Headerimage something="2" />
                        </ Imagecontainer>
                    </ FlexdividerR>
                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <Imagecontainer>
                            <Headerimage something="3" />
                        </ Imagecontainer>
                        <div>
                            <Subheader>
                                Blancco
                            </Subheader>
                            <Paragraph>
                                Green Digital is officieel partner van Blancco. Blancco Data Erasure Software is mondiaal leider voor het veilig verwijderen van gegevens op datadragers. Blancco is geaccrediteerd door de Nederlands Algemene Inlichtingen en Veiligheids Dienst (AIVD). 
                            </ Paragraph>
                        </ div>

                    </ Flexdivider>
                </Vertpad>
                <Vertpad>
                    <FlexdividerR>
                        <div>
                            <Subheader>
                                DIN 66399
                            </Subheader>
                            <Paragraph>
                                De DIN 66399 is de Europese Veiligheid Standaard voor de vernietiging van alle type datadragers. De multi-media shredder van Green Digital is DIN66399 gecertificeerd.  Deze nieuwe DIN standaard is ontwikkeld door de Deutsch Industrial Norm (DIN) en bevat de richtlijnen om commerciële organisaties en overheden te ondersteunen bij de keuze en garantie voor veilige mechanische datavernietiging.
                            </ Paragraph>
                        </ div>
                        <Imagecontainer>
                            <Headerimage something="4" />
                        </ Imagecontainer>
                    </ FlexdividerR>
                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <Imagecontainer>
                            <Headerimage something="5" />
                        </ Imagecontainer>
                        <div>
                            <Subheader>
                                SBB Erkend Leerbedrijf
                            </Subheader>
                            <Paragraph>
                                Sinds mei 2017 is Green Digital door de Samenwerkingsorganisatie Beroepsonderwijs Bedrijfsleven (SBB) erkend als leerbedrijf. De Samenwerkingsorganisatie Beroepsonderwijs Bedrijfsleven of kortweg SBB, is op 1 augustus 2015 in het leven geroepen om beroepsonderwijs en bedrijfsleven beter op elkaar af te stemmen. De twee partijen werken samen om studenten de beste praktijkopleiding te geven en uitzicht op een baan. Zo krijgen studenten de beste opleiding en krijgen bedrijven de vakmensen die ze nodig hebben. Green Digital voldoet aan alle voorwaarden om leerlingen van het VMBO en MBO een goede leer/werkplek te bieden die voldoet aan de eisen die voor de opleiding gesteld zijn.
                            </ Paragraph>
                        </ div>

                    </ Flexdivider>
                </Vertpad>
                <Vertpad>

                </Vertpad>

            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default Certificering


